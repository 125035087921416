let didScroll;
const body = $("body"),
    toggler = $(".navbar-toggler"),
    languageButton = $(".language .btn"),
    nav = $(".fixed-top"),
    group = $('#group'),
    carouselCurrent = $('.carousel-count'),
    carouselItem = $('.carousel-item'),
    navbarHeight = nav.outerHeight();


$(document).ready(function () {
    getPage();
    setNavScrolled();
    setNavActive();

    carouselCurrent && carouselCurrent.html(1 + '/' + carouselItem.length);
});

$(window).on('scroll', function () {
    didScroll = true;
    setNavScrolled();
});

$(window).on('resize', function () {
    onResize();
});

const onResize = function () {
    $(".top-section").css("padding-top", nav.height());
    setNavScrolled();
};

function setNavScrolled() {
    const height = $(".top-bar").height() > 0 ? $(".top-bar").height() : navbarHeight / 2;
    $(document).scrollTop() > height ?
        nav.addClass('scrolled') :
        nav.removeClass('scrolled');
}

function getPage() {
    let intro = document.getElementsByClassName('intro-section');
    let landing = document.getElementsByClassName('landing-top');

    intro[0] && document.body.classList.add('intro');
    landing[0] && document.body.classList.add('landing');
}

function setNavActive() {
    let pathname = window.location.pathname;

    $('.nav-item').each(function () {
        if (pathname.includes($(this).find('a').attr('href'))) $(this).addClass('active');
        else $(this).removeClass('active')
    });
}

$(".language a").on('click', function () {
    let txt = $(this).text();
    $(languageButton).text(txt);
});

$(".type-filter").on('click', function () {
    $(this).addClass('active');
    $(this).siblings().removeClass('active');

    let target = $(this).attr('aria-target');

    $('.product-card').each(function () {
        let type = $(this).attr('aria-type');

        if (target) {
            target === type ? $(this).show() : $(this).hide();
        } else {
            $(this).show()
        }
    });
});


group.on('show.bs.collapse', '.collapse', function () {
    group.find('.collapse.show').collapse('hide');
});


$('#carousel').on('slid.bs.carousel', function () {
    let currentIndex = $('.carousel-item.active').index() + 1;
    let totalItems = carouselItem.length;

    carouselCurrent.html(currentIndex + '/' + totalItems);
});


